import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/certificates', config);
}
const get = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/certificates/' + code);
}
const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/certificates/' + data.id, data);
    }
    return Api.post('/certificates', data);
}
const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/certificates/' + id, formData);
}
const uploadPdf = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/certificates/upload',formData);
}
const deleteCertifica = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/certificates/' + id);
}

export default {
    getAll,
    get,
    store,
    update,
    deleteCertifica,
    uploadPdf
}
